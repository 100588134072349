import React from 'react';
import WebriQForm from "@webriq/gatsby-webriq-form"
import Modal from "../modal/modal"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const TrialSection = () => <section
              className="quick-base-trial-banner"
              style={{ padding: "3rem 0" }}
            >
              <Container>
                <Row>
                  <Col md={`${9} mx-auto`}>
                    <div className="content text-center">
                      <Link aria-label="link" to="/">
                        <StaticImage
                          placeholder="blurred"
                          imgClassName="mb-3"
                          src="../../static/assets/footer-logo.png"
                          alt="Quandary Logo White"
                          layout="constrained"
                          loading="lazy"
                        />
                      </Link>
                      <h4 className="text-white mt-3">
                        Custom Applications Built Faster
                      </h4>
                      <hr className="bg-light" />
                      <h1
                        className="text-light-blue"
                        style={{ color: "#2C9382" }}
                      >
                        Get a Free Trial of Quickbase
                      </h1>
                      <hr className="bg-light" />
                      <Modal
                        modalSize="lg"
                        modalClass="modal-video"
                        btnClass="btn-play"
                        html={
                          <StaticImage
                            placeholder="blurred"
                            imgClassName="mb-3"
                            src="../images/playbuttonslimwhite.png"
                            alt="play button"
                            layout="constrained"
                            style={{ width: 110, height: 110 }}
                            loading="lazy"
                          />
                        }
                      >
                        <iframe
                          title="video"
                          width="100%"
                          height={506}
                          src="https://www.youtube.com/embed/qTuKCkQ9z3w"
                          frameBorder={0}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </Modal>
                      <p className="my-3 text-18 text-white">
                        Break free from spreadsheets with custom applications
                        designed for your unique business needs.
                      </p>
                      {/* <p className="my-3 text-18 text-white">
                      Need more details? Check out the video below for more
                      information on Quickbase
                    </p> */}
                      <div className="d-flex justify-content-center mt-4">
                        <Modal
                          modalSize="md"
                          modalClass="modal-signup"
                          btnClass="btn btn-quick-signup"
                          html={
                            <div>
                              <i
                                className="fa fa-check-square-o"
                                aria-hidden="true"
                              ></i>
                              &nbsp;&nbsp;TRY FREE FOR 30 DAYS!
                            </div>
                          }
                        >
                          <div id="signupForm" className="px-4 pb-4">
                            <div className="content text-center">
                              <StaticImage
                                placeholder="blurred"
                                src="../images/QCG-Primary-logo-_1_.png"
                                alt="Quandary logo"
                                layout="constrained"
                                style={{ width: 250 }}
                                loading="lazy"
                              />
                              <div
                                className="p-2 my-4"
                                style={{ backgroundColor: "#2C9382" }}
                              >
                                <h4 className="text-white m-0 font-weight-normal">
                                  CLAIM YOUR FREE TRIAL!
                                </h4>
                              </div>
                              <p className="text-dark">
                                Enter your name and email address below to claim
                                your
                                <br />
                                <span className="font-weight-bold">
                                  30-Day Free Trial of Quickbase TODAY!
                                </span>
                              </p>
                              <WebriQForm
                                id="freeTrialform"
                                name="30-Day Free Trial of Quickbase Form"
                                className="signup-form"
                                data-form-id="5e324dbcaf30e913f85ad570"
                                data-thankyou-url="/thank-you"
                              >
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="First Name"
                                    className="form-control"
                                    placeholder="First Name"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="Last Name"
                                    className="form-control"
                                    placeholder="Last Name"
                                    required
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="email"
                                    name="Email"
                                    className="form-control"
                                    placeholder="Your Best Email Address"
                                    required
                                  />
                                </div>
                                <div className="form-group mt-4">
                                  <div className="webriq-recaptcha" />
                                </div>
                                <div className="form-group mt-4">
                                  <button
                                    aria-label="btn"
                                    type="submit"
                                    className="btn btn-primary free-animate btn-quick animated infinite swing slow text-18 w-100"
                                  >
                                    <i
                                      className="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    YES, SIGN ME UP!
                                  </button>
                                </div>
                              </WebriQForm>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>

export default TrialSection;
