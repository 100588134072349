import React from "react"
import { Modal } from "react-bootstrap"

class FormModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow(e) {
    this.setState({ show: true })
    e.preventDefault()
  }

  render() {
    const {
      btnClass,
      modalClass,
      modalSize,
      children,
      html,
      id,
      modalTitle,
    } = this.props
    return (
      <>
        <button
          className={btnClass}
          onClick={this.handleShow}
          aria-label="button"
        >
          {html}
        </button>
        <Modal
          centered
          id={id}
          size={modalSize ? modalSize : ""}
          className={modalClass}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header className="border-0" closeButton>
            {modalTitle}
          </Modal.Header>
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      </>
    )
  }
}

export default FormModal
